<mat-card class="p-0">
  <mat-card-title class="">
      <div class="card-title-text">Profile Info</div>
      <mat-divider></mat-divider>
  </mat-card-title>
  <mat-card-content>
      <form [formGroup]="basicForm" (submit)="submit()">
          <div fxLayout="row wrap">
              <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">
                  
                <div class="pb-1">
                      <mat-form-field class="full-width">
                          <input
                          matInput
                          name="firstName"
                          formControlName="firstName"
                          placeholder="First name">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['firstName'].hasError('required') && basicForm.controls['firstName'].touched" 
                      class="form-error-msg"> First name is required </small>
                  </div>

                  <div class="pb-1">
                    <mat-form-field class="full-width">
                        <input
                          matInput
                          name="username"
                          formControlName="username"
                          placeholder="Username (Min Length: 5, Max Length: 9)">
                    </mat-form-field>
                    <small 
                    *ngIf="basicForm.controls['username'].hasError('minlength') && basicForm.controls['username'].touched" 
                    class="form-error-msg"> Username require at least {{basicForm.controls['username'].errors.minlength.requiredLength}} characters </small>
                    <small 
                    *ngIf="basicForm.controls['username'].hasError('maxlength') && basicForm.controls['username'].touched" 
                    class="form-error-msg"> Username can not contain more than {{basicForm.controls['username'].errors.maxlength.requiredLength}} characters </small>
                </div>

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                      <input
                          matInput
                          type="email"
                          name="email"
                          formControlName="email"
                          placeholder="Your Email">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['email'].hasError('required') && basicForm.controls['email'].touched" 
                      class="form-error-msg"> Email is required </small>
                      <small 
                      *ngIf="basicForm.controls['email'].hasError('email') && basicForm.controls['email'].touched" 
                      class="form-error-msg"> Invaild email address </small>
                  </div>

                  

              </div>

              <div fxFlex="100" fxFlex.gt-xs="50">

                <div class="pb-1">
                    <mat-form-field class="full-width">
                        <input
                        matInput
                        name="lastName"
                        formControlName="lastName"
                        placeholder="Last name">
                    </mat-form-field>
                    <small 
                    *ngIf="basicForm.controls['lastName'].hasError('required') && basicForm.controls['lastName'].touched" 
                    class="form-error-msg"> Last name is required </small>
                </div>

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                          <input 
                            matInput
                            name="phone"
                            formControlName="phone"
                            placeholder="Phone number">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['phone'].hasError('phone') && basicForm.controls['phone'].touched" 
                      class="form-error-msg"> Invaild phone number </small>
                  </div>

                 
                  <div class="pb-1">
                    <mat-form-field class="full-width">
                    <input
                        matInput
                        name="address"
                        formControlName="address"
                        placeholder="Your Address">
                    </mat-form-field>
                    <small 
                    *ngIf="basicForm.controls['address'].hasError('required') && basicForm.controls['address'].touched" 
                    class="form-error-msg"> Address is required </small>
                </div>
                  

                 
              </div>
          </div>

         
            <button 
              mat-raised-button 
              color="primary" 
              [disabled]="basicForm.invalid">Submit</button>
          
      </form>
  </mat-card-content>
</mat-card>