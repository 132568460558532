import { EditConfigComponent } from './components/edit-config/edit-config.component';
import { ListUsersComponent } from './components/users/list-users/list-users.component';
import { SigninStylesMiddlewareComponent } from './components/auth/signin/signin-styles-middleware/signin-styles-middleware.component';
import { SignupStylesMiddlewareComponent } from './components/auth/signup/signup-styles-middleware/signup-styles-middleware.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { EditProfileComponent } from './components/users/edit-profile/edit-profile.component';
import { ProfileResolver } from './resolvers/profile.resolver';
import { ProfileComponent } from './components/users/profile/profile.component';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { SigninComponent } from './components/auth/signin/signin.component';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { UsersListResolver } from './resolvers/users-list.resolver';
import { ConfigResolver } from './resolvers/config.resolver';
import { AdminConfigResolver } from './resolvers/admin-config.resolver';

export const rootRouterConfig: Routes = [
  // Landing page
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    
  },
  // auth stuff
  {
    path: '', 
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth/signin',
        component: SigninComponent,
        data: {title: "Sign in"}
      },
      // {
      //   path: 'register',
      //   component: SignupComponent,
      //   data: { title: "Sign up"}
      // },
      // {
      //   path: 'forgot-password',
      //   component: ForgotPasswordComponent,
      //   data: { title: "Forgot Password"}
      // },
      // {
      //   path: 'reset/:userId/:token',
      //   component: ResetPasswordComponent,
      //   data: {title: "Reset Password"}
      // }
    ]
  },
  // redirection stuff
  {
    path: '', 
    component: AdminLayoutComponent,
    children: [
      {
        path: 'signup',
        component: SignupStylesMiddlewareComponent,
        data: { title: "Sign up"}
      },
      {
        path: 'signin',
        component: SigninStylesMiddlewareComponent,
        data: {title: "Sign in"}
      }
    ]
  },
  {
    path: '', 
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        children: [
          {
            path: 'all',
            component: ListUsersComponent,
            data: { title: "All Users", breadcrumb: "All Users"},
            resolve: { users: UsersListResolver}
          },
          {
            path: 'add',
            component: AddUserComponent,
            data: { title: 'Add User', breadcrumb: 'Add User'}
          },
          
          {
            path: 'me/edit',
            component: EditProfileComponent,
            data: { title: 'Edit Profile', breadcrumb: 'Edit Profile'},
            resolve: { me: ProfileResolver }
          },
          {
            path: 'me',
            component: ProfileComponent,
            data: { title: 'Profile', breadcrumb: 'Profile'},
            resolve: { me: ProfileResolver }
          },
          {
            path: ':id/config',
            children: [
              {
                path: 'edit',
                component: EditConfigComponent,
                data: { title: "Edit Config", breadcrumb: "Edit Config"},
                resolve: { config: AdminConfigResolver}
              }
            ]
          },
        ]
      },
      {
        path: 'config/edit',
        component: EditConfigComponent,
        data: { title: "Edit Config", breadcrumb: "Edit Config"},
        resolve: { config: ConfigResolver }
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: "Dashboard" }
      }
    ]
  },
  { 
    path: '**', 
    redirectTo: 'sessions/404'
  }
]

