<mat-card class="default">
  <mat-card-title>Personal Information</mat-card-title>
  <br/>
  <mat-card-content class="pt-0">
    <div fxLayout="row wrap">
      <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">
                    
        <div class="pb-1">
          <h6><strong>First Name</strong></h6>
          <p>{{user.firstName}}</p>
        </div>

        <div class="pb-1">
          <h6><strong>Username</strong></h6>
          <p>{{user.username}}</p>
        </div>

        <div class="pb-1">
          <h6><strong>Email</strong></h6>
          <p>{{user.email}}</p>
        </div>
                         
        <button 
              mat-raised-button 
              color="primary" [routerLink]="['edit']">Edit</button>

      </div>

      <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">
                    
        <div class="pb-1">
          <h6><strong>Last Name</strong></h6>
          <p>{{user.lastName}}</p>
        </div>

        <div class="pb-1">
          <h6><strong>Phone</strong></h6>
          <p>{{user.phone}}</p>
        </div>
        <div class="pb-1">
          <h6><strong>Address</strong></h6>
          <p>{{user.address}}</p>
        </div>
        
      </div>
  </div>

  
  </mat-card-content>
</mat-card>

<div>
</div>