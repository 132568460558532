import { RegisterInfo } from './../models/register';
import { User } from './../models/user';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.apiURL + 'auth';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;

  constructor(private http: HttpClient) {   }

  login(model: any) {
    return this.http.post(this.baseUrl, model)
      .pipe(
        map(
          (response: any) => {
            const user = response;
            if(user) {
              localStorage.setItem('token', user.token);
              localStorage.setItem('user', JSON.stringify(user.user));
              this.decodedToken = this.jwtHelper.decodeToken(user.token);
              this.currentUser = user.user;
            }
          }
        )
      );
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  logout() {
    localStorage.removeItem('token');
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  
  getToken() {
    return localStorage.getItem('token');
  }

  resetPassword(email) {
    return this.http.post(this.baseUrl+'/reset-password', {email});
  }

  storePassword(token, userId, password) {
    return this.http.post(this.baseUrl+'/store-password', {token: token, _id: userId, password: password});
  }
 
  register(registerInfo: RegisterInfo) {
    return this.http.post(this.baseUrl+'/register', registerInfo);
  }

}
