import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signin-styles-middleware',
  templateUrl: './signin-styles-middleware.component.html',
  styleUrls: ['./signin-styles-middleware.component.scss']
})
export class SigninStylesMiddlewareComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    console.log('signing in...');
    this.router.navigate(['/auth', 'signin']);
  }

}
