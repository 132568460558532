import { AuthService } from './../services/auth.service';
import { UserService } from './../services/user.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { User } from "app/models/user";
import { Observable } from 'rxjs';



export class ConfigResolver implements Resolve<any> {
    constructor(private userService: UserService, private auth: AuthService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
        return this.userService.getConfig(this.auth.getUser()._id);
    }

}