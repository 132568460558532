import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from 'app/models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl = environment.apiURL + 'users';

  constructor(private http: HttpClient) { }

  addUser(user: User) {
    return this.http.post(this.baseUrl, user);
  }

  getProfile() {
    return this.http.get(this.baseUrl + '/me', {observe: 'response'})
      .pipe(
        map(
          (response: any) => {
            return response.body;
          }
        )
      );
  }

  getAllUsers() {
    return this.http.get(this.baseUrl);
  }

  getArtists() {
    return this.http.get(this.baseUrl+'/artists');
  }

  getConfig(userId) {
    return this.http.get(this.baseUrl+'/'+userId+'/config');
  }

  saveConfig(config, userId) {
    return this.http.post(this.baseUrl+'/'+userId+'/config', config);
  }
  issueLicense(userId) {
    return this.http.post(environment.apiURL+'license/issue/'+userId, null);
  }
}
