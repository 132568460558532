import { AuthService } from './../../services/auth.service';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor(private auth: AuthService) {}
  adminIconMenu: IMenuItem[] = [
   
    {
      name: "Users",
      type: "dropDown",
      tooltip: "Users",
      icon: "person",
      state: "users",
      sub: [
        { name: "Add", state: "add"},
        {name: "View All", state: "all"}
      ]
    }
  ];

  customerIconMenu: IMenuItem[] = [
   
    {
      name: "Edit Config",
      type: "link",
      tooltip: "Edit Config",
      icon: "edit",
      state: "config/edit",
    }
  ];
 

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  // menuItems = new BehaviorSubject<IMenuItem[]>(this.adminIconMenu);
  menuItems = this.getMenuItems();
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
      this.menuItems.next([]);
  }
  getMenuItems() {
    if(this.auth.getUser())
    switch (this.auth.getUser().role) {
      case "Admin":
        return new BehaviorSubject<IMenuItem[]>(this.adminIconMenu);
        break;
      case "Customer":
        return new BehaviorSubject<IMenuItem[]>(this.customerIconMenu);
        break;
      
      default:
        return new BehaviorSubject<IMenuItem[]>([]);
        break;
    }
    return new BehaviorSubject<IMenuItem[]>([]);
  }

  getMenuItemsSync() {
    if(this.auth.getUser())
    switch (this.auth.getUser().role) {
      case "Admin":
        return this.adminIconMenu;
        break;
      case "Customer":
        return this.customerIconMenu;
        break;
      
      default:
        return [];
        break;
    }
    return [];
  }
}
