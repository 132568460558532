<div class="page-wrap height-100 black">
  <div class="session-form-hold">
    <mat-progress-bar mode="determinate" class="session-progress"></mat-progress-bar>
    <mat-card>
      <mat-card-content>
        <div class="text-center pt-8 pb-16">
          <img width="60px" src="assets/images/logo-lg.png" alt="" class="mb-05">
          <p class="text-muted m-0">Sign in to your account</p>
        </div>
        <form [formGroup]="signinForm" (ngSubmit)="signin()">
          <div class="">
            <mat-form-field class="full-width">
              <input
                matInput
                type="email"
                name="email"
                [formControl]="signinForm.controls['email']"
                placeholder="Email Address"
                value="">
            </mat-form-field>
            <small 
              *ngIf="signinForm.controls['email'].hasError('required') && signinForm.controls['email'].touched" 
              class="form-error-msg"> Email is required </small>

              <small 
                *ngIf="signinForm.controls['email'].hasError('email') && signinForm.controls['email'].touched" 
                class="form-error-msg"> Invaild email address </small>
          </div>
          

          <div class="">
            <mat-form-field class="full-width">
              <input 
                type="password"
                name="password"
                matInput
                [formControl]="signinForm.controls['password']"
                placeholder="Password" 
                value="">
            </mat-form-field>
            <small 
              *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched" 
              class="form-error-msg"> Password is required </small>
          </div>
          
          <button mat-raised-button class="mat-primary full-width mb-1" [disabled]="signinForm.invalid">Sign in</button>
          
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

