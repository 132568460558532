import { Component, OnInit } from '@angular/core';
import { Roles } from 'app/models/roles';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'app/services/auth.service';
import { UserService } from 'app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  formData = {}
  console = console;
  roles = Roles;
  basicForm: FormGroup;
  user: any;
  allowEdit: Boolean = false;

  constructor(private auth: AuthService,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data['me'];

      this.basicForm = new FormGroup({
        username: new FormControl(this.user.username, [
          Validators.minLength(4),
          Validators.maxLength(9)
        ]),
        firstName: new FormControl(this.user.firstName, [
          Validators.required
        ]),
        email: new FormControl(this.user.email, [
          Validators.required,
          Validators.email
        ]),
        address: new FormControl(this.user.address, [
          Validators.required
        ]),
        lastName: new FormControl(this.user.lastName, [
          Validators.required
        ]),
        phone: new FormControl(this.user.phone, CustomValidators.phone('BD')),
      })
      });
    
  }

  submit() {
    this.console.log(this.basicForm.value);

  }
  
}
