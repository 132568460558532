import { MatSnackBar } from '@angular/material';
import { AuthService } from './../../services/auth.service';
import { UserService } from 'app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.scss']
})
export class EditConfigComponent implements OnInit {

  config: any;
  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private userService: UserService, private auth: AuthService) { }
  mongoAuthDb: String;
  mongoHost1: String;
  mongoHost2: String;
  mongoHost3: String;
  mongoPassword: String;
  mongoPort: String;
  mongoUser: String;
  rabbitmqHost: String;
  rabbitmqPassword: String;
  rabbitmqPort: String;
  rabbitmqUserName: String;
  redisHost: String;
  redisPassword: String;
  redisPort: String;
  superUserPassword: String;
  superUserUserName: String;

  ngOnInit() {
    this.route.data.subscribe(
      (res) => {
        this.config = res.config.config;
        this.mongoAuthDb = this.config['mongo-auth-db'];
        this.mongoHost1 = this.config['mongo-host-1'];
        this.mongoHost2 = this.config['mongo-host-2'];
        this.mongoHost3 = this.config['mongo-host-3'];
        this.mongoPassword = this.config['mongo-password'];
        this.mongoPort = this.config['mongo-port'];
        this.mongoUser = this.config['mongo-user'];
        this.rabbitmqHost = this.config['rabbitmq-host'];
        this.rabbitmqPassword = this.config['rabbitmq-password'];
        this.rabbitmqPort = this.config['rabbitmq-port'];
        this.rabbitmqUserName = this.config['rabbitmq-userName'];
        this.redisHost = this.config['redis-host'];
        this.redisPassword = this.config['redis-password'];
        this.redisPort = this.config['redis-port'];
        this.superUserPassword = this.config['superUser']['password'];
        this.superUserUserName = this.config['superUser']['userName'];
      }
    )
    // this.config = {superUser: {}};
  }

  submitForm() {
    this.config['mongo-auth-db'] = this.mongoAuthDb;
    this.config['mongo-host-1'] = this.mongoHost1;
    this.config['mongo-host-2'] = this.mongoHost2;
    this.config['mongo-host-3'] = this.mongoHost3;
    this.config['mongo-password']= this.mongoPassword;
    this.config['mongo-port'] = this.mongoPort;
    this.config['mongo-user'] = this.mongoUser;
    this.config['rabbitmq-host'] = this.rabbitmqHost;
    this.config['amqp-host'] = this.rabbitmqHost;
    this.config['rabbitmq-password'] = this.rabbitmqPassword;
    this.config['amqp-pass'] = this.rabbitmqPassword;
    this.config['rabbitmq-port'] = this.rabbitmqPort;
    this.config['amqp-port'] = this.rabbitmqPort;
    this.config['rabbitmq-userName'] = this.rabbitmqUserName;
    this.config['amqp-user'] = this.rabbitmqUserName;
    this.config['redis-host'] = this.redisHost;
    this.config['redis-password'] = this.redisPassword;
    this.config['redis-port'] = this.redisPort;
    this.config['superUser']['password'] = this.superUserPassword;
    this.config['superUser']['userName'] = this.superUserUserName;
    this.userService.saveConfig(this.config, this.auth.getUser()._id).subscribe(
      (res) => {
        this.snackBar.open("Config saved successfully.", "OK", { duration: 2000});
      }, err => {
        this.snackBar.open("Config not saved.", "OK", { duration: 2000});
        console.log(err);
      }
    );
  }

}
