import { MatSnackBar } from '@angular/material';
import { User } from './../../../models/user';
import { Router } from '@angular/router';
import { UserService } from './../../../services/user.service';
import { Roles } from './../../../models/roles';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  formData = {}
  console = console;
  roles = [];
  basicForm: FormGroup;

  constructor(private userService: UserService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.roles = Object.keys(Roles);
    let password = new FormControl('', Validators.required);
    let confirmPassword = new FormControl('', CustomValidators.equalTo(password));

    this.basicForm = new FormGroup({
     
      role: new FormControl('', [
        Validators.required
      ]),
      name: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      
      password: password,
      confirmPassword: confirmPassword,
    })
  }

  submit() {
    this.userService.addUser(this.basicForm.value as User).subscribe(
      (response) => {
        this.snackBar.open("User added.", "OK", {duration: 2000});
        this.router.navigate(['dashboard']);
      }, (error) => {
        this.snackBar.open(error.error, "OK", {duration: 2000});
        console.log(error.error);
      }
    );
    this.console.log(this.basicForm.value);
  }
  
}
