<mat-card class="p-0">
  <mat-card-title class="">
      <div class="card-title-text">Add User</div>
      <mat-divider></mat-divider>
  </mat-card-title>
  <mat-card-content>
      <form>
          <div fxLayout="row wrap">

         
              <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">

                

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                          <input
                            matInput
                            #rabbitmqhost="ngModel"
                            [(ngModel)]="rabbitmqHost"
                            name="rabbitmqhost"
                            placeholder="RabbitMQ Host"
                            value="">
                      </mat-form-field>
                      <small 
                      *ngIf="rabbitmqhost.hasError('required') && rabbitmqhost.touched" 
                      class="form-error-msg"> *Required</small>
                  </div>

                  <div class="pb-1">
                    <mat-form-field class="full-width">
                        <input
                          matInput
                          #rabbitmqport="ngModel"
                          [(ngModel)]="rabbitmqPort"
                          name="rabbitmqport"
                          placeholder="RabbitMQ Port"
                          value="">
                    </mat-form-field>
                    <small 
                    *ngIf="rabbitmqport.hasError('required') && rabbitmqport.touched" 
                    class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongohost1="ngModel"
                        [(ngModel)]="mongoHost1"
                        name="mongohost1"
                        placeholder="Mongo Host 1"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="mongohost1.hasError('required') && mongohost1.touched" 
                  class="form-error-msg"> *Required </small>
                </div>

             
                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongohost3="ngModel"
                        [(ngModel)]="mongoHost3"
                        name="mongohost3"
                        placeholder="Mongo Host 3"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="mongohost3.hasError('required') && mongohost3.touched" 
                  class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongoauthdb="ngModel"
                        [(ngModel)]="mongoAuthDb"
                        name="mongoauthdb"
                        placeholder="Mongo Auth DB"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="mongoauthdb.hasError('required') && mongoauthdb.touched" 
                  class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongopassword="ngModel"
                        [(ngModel)]="mongoPassword"
                        name="mongopassword"
                        placeholder="Mongo Password"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="mongopassword.hasError('required') && mongopassword.touched" 
                  class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #redisport="ngModel"
                        [(ngModel)]="redisPort"
                        name="redisport"
                        placeholder="Redis Port"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="redisport.hasError('required') && redisport.touched" 
                  class="form-error-msg"> *Required </small>
                </div>
                
                
                 
                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #userName="ngModel"
                        [(ngModel)]="superUserUserName"
                        name="userName"
                        placeholder="Super User's Username"
                        value="">
                  </mat-form-field>
                  <small 
                  *ngIf="userName.hasError('required') && userName.touched" 
                  class="form-error-msg"> *Required </small>
                </div>
              </div>

              <div fxFlex="100" fxFlex.gt-xs="50">

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #rabbitmquserName="ngModel"
                        [(ngModel)]="rabbitmqUserName"
                        name="rabbitmquserName"
                        placeholder="RabbitMQ Username"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="rabbitmquserName.hasError('required') && rabbitmquserName.touched" 
                    class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #rabbitmqpassword="ngModel"
                        [(ngModel)]="rabbitmqPassword"
                        name="rabbitmqpassword"
                        placeholder="RabbitMQ Password"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="rabbitmqpassword.hasError('required') && rabbitmqpassword.touched" 
                    class="form-error-msg"> *Required </small>
                </div>
                  
                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongohost2="ngModel"
                        [(ngModel)]="mongoHost2"
                        name="mongohost2"
                        placeholder="Mongo Host 2"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="mongohost2.hasError('required') && mongohost2.touched" 
                    class="form-error-msg"> *Required </small>
                </div>
                  
           
                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongoport="ngModel"
                        [(ngModel)]="mongoPort"
                        name="mongoport"
                        placeholder="Mongo Port"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="mongoport.hasError('required') && mongoport.touched" 
                    class="form-error-msg"> *Required </small>
                </div>
                  
                
                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #mongouser="ngModel"
                        [(ngModel)]="mongoUser"
                        name="mongouser"
                        placeholder="Mongo Port"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="mongouser.hasError('required') && mongouser.touched" 
                    class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #redishost="ngModel"
                        [(ngModel)]="redisHost"
                        name="redishost"
                        placeholder="Redis Host"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="redishost.hasError('required') && redishost.touched" 
                    class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #redispassword="ngModel"
                        [(ngModel)]="redisPassword"
                        name="redispassword"
                        placeholder="Redis Password"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="redispassword.hasError('required') && redispassword.touched" 
                    class="form-error-msg"> *Required </small>
                </div>

                <div class="pb-1">
                  <mat-form-field class="full-width">
                      <input
                        matInput
                        #userPassword="ngModel"
                        [(ngModel)]="superUserPassword"
                        name="userPassword"
                        placeholder="Super User's Password"
                        value="">
                  </mat-form-field>
                  <small 
                    *ngIf="userPassword.hasError('required') && userPassword.touched" 
                    class="form-error-msg"> *Required </small>
                </div>
                 
              </div>
          </div>
          <button 
          mat-raised-button 
          color="primary" (click)="submitForm()">Submit</button>
      </form>
  </mat-card-content>
</mat-card>