import { UserService } from './../services/user.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { User } from "app/models/user";
import { Observable } from 'rxjs';



export class UsersListResolver implements Resolve<any> {
    constructor(private userService: UserService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Promise<any> {
        return this.userService.getAllUsers();
    }

}