import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar, MatButton, MatSnackBar } from '@angular/material';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  @ViewChild(MatProgressBar, {static: false}) progressBar: MatProgressBar;
  @ViewChild(MatButton, {static: false}) submitButton: MatButton;

  signinForm: FormGroup;

  constructor(private authService: AuthService, private router: Router, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.signinForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    })
  }

  signin() {
    const signinData = this.signinForm.value
    console.log(signinData);

    this.submitButton.disabled = true;
    this.progressBar.mode = 'indeterminate';
    this.authService.login(signinData)
    .subscribe(
      (res) => {
        this.snackbar.open('Logged in', 'OK', { duration: 2 * 1000});
        this.router.navigate(['/dashboard']);
      },
      (err) => {
        console.log(err);
        this.snackbar.open('Please enter a correct email or password', 'OK', { duration: 3 * 1000});
        this.submitButton.disabled = false;
        this.progressBar.mode = null;
      }
    )
  }
}
