<mat-card class="p-0">
  <mat-card-title class="">
      <div class="card-title-text">Add User</div>
      <mat-divider></mat-divider>
  </mat-card-title>
  <mat-card-content>
      <form [formGroup]="basicForm" (submit)="submit()">
          <div fxLayout="row wrap">

            <div fxFlex="100" fxFlex.gt-xs="100" class="pr-1">

                <div class="pb-1">
                    <mat-form-field>
                        <mat-label>User Type</mat-label>
                        <mat-select
                          matInput
                          name="role"
                          formControlName="role">
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor="let role of roles" [value]="role">
                            {{role}}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>

                    <small 
                      *ngIf="basicForm.controls['role'].hasError('required') && basicForm.controls['role'].touched" 
                      class="form-error-msg"> Must select one</small>
                </div>
                

            </div>


              <div fxFlex="100" fxFlex.gt-xs="50" class="pr-1">

                

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                          <input
                          matInput
                          name="name"
                          formControlName="name"
                          placeholder="First name"
                          value="">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['name'].hasError('required') && basicForm.controls['name'].touched" 
                      class="form-error-msg"> First name is required </small>
                  </div>

                  
                  <div class="pb-1">
                      <mat-form-field class="full-width">
                      <input
                          matInput
                          type="email"
                          name="email"
                          formControlName="email"
                          placeholder="Your Email"
                          value="">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['email'].hasError('required') && basicForm.controls['email'].touched" 
                      class="form-error-msg"> Email is required </small>
                      <small 
                      *ngIf="basicForm.controls['email'].hasError('email') && basicForm.controls['email'].touched" 
                      class="form-error-msg"> Invaild email address </small>
                  </div>

                 
              </div>

              <div fxFlex="100" fxFlex.gt-xs="50">

           


                  <div class="pb-1">
                      <mat-form-field class="full-width">
                      <input 
                          type="password"
                          name="password"
                          matInput
                          formControlName="password"
                          placeholder="Password" 
                          value="">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['password'].hasError('required') && basicForm.controls['password'].touched" 
                      class="form-error-msg"> Password is required </small>
                  </div>

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                      <input
                          type="password"
                          name="confirmPassword"
                          matInput
                          appEqualValidator="password"
                          formControlName="confirmPassword"
                          placeholder="Confirm Password"
                          value="">
                      </mat-form-field>
                      <small 
                      *ngIf="basicForm.controls['confirmPassword'].hasError('equalTo')" 
                      class="form-error-msg"> Password mismatch </small>
                  </div>

                 
              </div>
          </div>
          <button 
          mat-raised-button 
          color="primary" 
          [disabled]="basicForm.invalid">Submit</button>
      </form>
  </mat-card-content>
</mat-card>