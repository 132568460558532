import { Component, OnInit } from '@angular/core';
import { Roles } from 'app/models/roles';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { User } from '../../../models/user';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: any;

  constructor(private auth: AuthService,
              private userService: UserService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.user = data['me'];
      console.log(this.user);
    });
    
  }

}
