<mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
    <!-- name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>  Name </th>
      <td mat-cell *matCellDef="let element; let i = index "><a [routerLink]="[element._id]">{{element.name}} </a> </td>
    </ng-container>
  
     <!-- Date Column -->
     <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email  </th>
      <td mat-cell *matCellDef="let element; let i = index "> {{element.email}} </td>
    </ng-container>
  
   
   
    <!-- Actionc Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="issueLicense(element)" color="primary">Issue License</button>
        <button mat-raised-button [routerLink]="['../',element._id, 'config', 'edit']" color="primary">Edit Config</button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  