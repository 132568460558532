import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-signup-styles-middleware',
  templateUrl: './signup-styles-middleware.component.html',
  styleUrls: ['./signup-styles-middleware.component.scss']
})
export class SignupStylesMiddlewareComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.router.navigate(['invitation', this.route.snapshot.params['token'], 'register'])
  }

}
