import { AdminConfigResolver } from './resolvers/admin-config.resolver';
import { UsersListResolver } from './resolvers/users-list.resolver';
import { ProfileResolver } from './resolvers/profile.resolver';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatDividerModule, MatCardTitle, MatCardModule, MatInputModule, MatListModule, MatDatepickerModule, MatNativeDateModule, MatProgressBarModule, MatRadioModule, MatCheckboxModule, MatButtonModule, MatIconModule, MatStepperModule, MatSelectModule, MatTableModule, MatDialogModule } from '@angular/material';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';


import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';

import { QuillModule } from 'ngx-quill';
import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { AuthInterceptor } from './AuthInterceptor';
import { AddUserComponent } from './components/users/add-user/add-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'ng2-file-upload';
import { DashboardComponent } from './components/home/dashboard/dashboard.component';
import { ProfileComponent } from './components/users/profile/profile.component';
import { EditProfileComponent } from './components/users/edit-profile/edit-profile.component';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { SigninComponent } from './components/auth/signin/signin.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/auth/reset-password/reset-password.component';
import { SignupStylesMiddlewareComponent } from './components/auth/signup/signup-styles-middleware/signup-styles-middleware.component';
import { SigninStylesMiddlewareComponent } from './components/auth/signin/signin-styles-middleware/signin-styles-middleware.component';
import { ListUsersComponent } from './components/users/list-users/list-users.component';
import { EditConfigComponent } from './components/edit-config/edit-config.component';
import { ConfigResolver } from './resolvers/config.resolver';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


const formsModulesToImport = [
  MatInputModule,
  MatListModule,
  MatCardModule,
  MatDatepickerModule, 
  MatNativeDateModule,
  MatProgressBarModule,
  MatRadioModule,
  MatCheckboxModule,
  MatButtonModule,
  MatIconModule,
  MatStepperModule,
  CommonModule,
  FormsModule,
  FlexLayoutModule,
  QuillModule,
  NgxDatatableModule,
  FileUploadModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatTableModule,
  MatDialogModule];


  const resolvers = [
    ProfileResolver,
    UsersListResolver,
    ConfigResolver,
    AdminConfigResolver
  ];

  const services = [
    AuthService,
    UserService,
  ];

@NgModule({
  imports: [
    BrowserModule,
    ...formsModulesToImport,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true}),
    RouterModule.forRoot(rootRouterConfig, { useHash: false })
  ],
  declarations: [
    AppComponent,
    AddUserComponent,
    SigninComponent,
    SignupComponent,
    DashboardComponent,
    ProfileComponent,
    EditProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SignupStylesMiddlewareComponent,
    SigninStylesMiddlewareComponent,
    ListUsersComponent,
    EditConfigComponent
  ],
  providers: [
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    ...resolvers,
    ...services
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }