import { UserService } from './../services/user.service';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { User } from "app/models/user";
import { Observable } from 'rxjs';



export class ProfileResolver implements Resolve<User> {
    constructor(private userService: UserService) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): User | Observable<User> | Promise<User> {
        return this.userService.getProfile();
    }

}