<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <!-- App Logo -->
            <div class="branding">
                <!-- <img src="assets/images/logo.png" alt="" class="app-logo"> -->
                <!-- <span class="app-logo-text">EGRET</span> -->
                
                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
                <!-- <div 
                class="sidebar-compact-switch"
                [ngClass]="{active: layoutConf.sidebarCompactToggle}"
                (click)="toggleCollapse()" 
                *ngIf="layoutConf.sidebarStyle !== 'compact'"><span></span></div> -->
            </div>
        
            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                    {{getUser().name[0] | uppercase}}
                </div>
                <span class="app-user-name mb-05">
                <mat-icon class="icon-xs text-muted">lock</mat-icon> 
                {{getUser().name}}
                </span>
                
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>