import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.css']
})
export class ListUsersComponent implements OnInit {
  displayedColumns: string[] = ['position'];
  dataSource: MatTableDataSource<any>; 
  user: any;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.route.data.subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data["users"]);
        this.displayedColumns = ['name', 'email',  'actions'];
      }
    );
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openSnackBar(message: string, action: string, duration: number) {
    this.snackBar.open(message, action, {
      duration: duration * 1000
    });
  }


  issueLicense(user: any) {
    this.userService.issueLicense(user._id).subscribe(
      (res) => {
        this.exportJson(res);
        this.snackBar.open("License issued successfully.", "OK", { duration: 2000 });
      }, err => {
        this.snackBar.open("An error occured. Please try again.", "OK", { duration: 2000 });
        console.log(err);
      }
    );
  }

  exportJson(res): void {
    console.log(res)
    const c = JSON.stringify(res);
    const file = new Blob([c], {type: 'text/json'});
    this.download(file,"fileName.json");
  }

  download(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(blob, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
    }
}
}
